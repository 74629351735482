@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/font/nunitosans/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'Nunito Sans Extra Light';
  src: url('./assets/font/nunitosans/NunitoSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'Nunito Sans Light';
  src: url('./assets/font/nunitosans/NunitoSans-Light.ttf');
}

@font-face {
  font-family: 'Nunito Sans SemiBold';
  src: url('./assets/font/nunitosans/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Nunito Sans ExtraBold';
  src: url('./assets/font/nunitosans/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'Cookie';
  src: url('./assets/font/cookie/Cookie-Regular.ttf');
}

@font-face {
  font-family: 'Signika Medium';
  src: url('./assets/font/signika/Signika-Medium.ttf');
}

@font-face {
  font-family: 'Signika Light';
  src: url('./assets/font/signika/Signika-Light.ttf');
}

@font-face {
  font-family: 'Signika Bold';
  src: url('./assets/font/signika/Signika-Bold.ttf');
}

[id^='global-modal'] {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 4;

  display: flex;
  justify-content: center;
  align-items: center;
}
